@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: local("Oswald"),
  url(../../fonts/Oswald-Regular.ttf) format("truetype");
}

html {
  scroll-behavior: smooth;

  @media (--headerMax) {
    scroll-padding: 120px;
  }
  @media (--header) and (--tabletMax) {
    scroll-padding: 140px;
  }
}

::selection {
  background: var(--c_main);
  color: #fff;
}

strong, b {
  font-weight: bold;
}

i, em {
  font-style: italic;
}


* {
  box-sizing: border-box;
  outline: none;
}

iframe {
  max-width: 100%;
  margin: 0 auto;
}

p {
  margin: 1rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:empty {
    display: none;
  }
}

ul, ol {
  margin: 1rem 0;
  font-weight: 300;
  list-style-position: inside;
  padding-left: 0;

  p + &,
  li > & {
    padding-left: 2.5rem;
  }
}

a {
  color: var(--c_white);
  text-decoration: none;
  cursor: pointer;
}

svg {
  max-width: 100%;
  max-height: 100%;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

label {
  cursor: pointer;
  display: inline-block;
}

[disabled] {
  opacity: .7;
  cursor: not-allowed;
  pointer-events: none;
}

input:read-only {
  opacity: 0.7;
}

input[type='submit']:read-only {
  opacity: 1;
}

[data-lazyimg] {
  background: #f5f5f5;
  font-size: .8em;
}

html {
  font-size: 10px;
  --width-scroll: 0;
}

body {
  margin-top: 90px;
  min-width: 320px;

  font-family: var(--font);
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.53333;

  color: var(--c_text);
  background-color: var(--c_gray);
  overflow-x: hidden;

  a {
    color: var(--c_text);
  }

  &.open_mob_nav {
    @media (--tablet) {
      overflow: hidden;
    }
  }

  @media (--mobile) {
    margin-top: 90px;
  }
}

.container,
.box-container {
  width: 100%;
  max-width: 1200px;
  padding: 0 22px;
  margin: 0 auto;

  &.this--not_padding {
    padding: 0;
  }

  &.this--max {
    max-width: var(--max_width);
  }
}

.section {
  padding: 0;

  &.this--top {
    padding-top: 60px;
  }

  &.this--bottom {
    padding-bottom: 60px;
  }

  &.this--overlay_top {
    position: relative;
    margin-top: -20px;
    z-index: 1;
  }

  @media (--tablet) {
    padding: 0;

    &.this--top {
      padding-top: 50px;
    }

    &.this--bottom {
      padding-bottom: 50px;
    }
  }

  @media (--mobile) {
    padding: 0;

    &.this--top {
      padding-top: 40px;
    }

    &.this--bottom {
      padding-bottom: 40px;
    }
  }
}

.section__title {
  margin-bottom: 30px;

  @media (--tablet) {
    margin-bottom: 20px;
  }

  &.this--logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (--tablet) {
      flex-direction: column;
    }
  }
}

.section__title_wrapper {

}

.section__title_logo {
  width: 200px;
  max-width: 200px;
  margin-left: 24px;

  @media (--tablet) {
    align-self: end;
    width: 180px;
    max-width: 180px;
  }
}

.section__title_plug {
  width: 200px;
  max-width: 200px;
  margin-right: 24px;
}

.sub_section {
  padding: 0;
  background-color: var(--c_white);

  &.this--top {
    padding-top: 30px;
  }

  &.this--bottom {
    padding-bottom: 30px;
  }

  &.this--not_bc {
    background-color: transparent;
  }

  &.this--side_padding {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (--tablet) {
    &.this--top {
      padding-top: 25px;
    }

    &.this--bottom {
      padding-bottom: 25px;
    }
  }

  @media (--mobile) {
    &.this--top {
      padding-top: 20px;
    }

    &.this--bottom {
      padding-bottom: 20px;
    }
  }
}
