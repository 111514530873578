.b_list_section {

}

.b_list_section__list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  margin-left: -20px;
  margin-bottom: -20px;

  list-style: none;

  @media (--mobile) {
    margin-right: 0;
  }
}

.b_list_section__item {
  display: flex;
  flex-direction: column;
  min-width: 224px;
  width: calc(25% - 20px);
  margin-left: 20px;
  margin-bottom: 20px;

  .this--tree & {
    width: calc(33.3333% - 20px);
  }

  @media (--tablet) {
    width: calc(50% - 20px);

    .this--tree & {
      width: calc(50% - 20px);
    }
  }

  @media (--mobile) {
    width: 100%;
    margin-right: 0;

    .this--tree & {
      width: 100%;
    }
  }
}

.b_list_section__cover {
  position: relative;

  background-color: var(--c_white);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.17);

  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 64%;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: right;
  }
}

.b_list_section__info {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 144px;

  margin-top: -32px;
  margin-left: 28px;
  margin-right: 28px;
  padding: 24px 36px;

  background-color: var(--c_white);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.17);

  z-index: 2;
  transition: all 0.2s;

  &.this--link {
    cursor: pointer;

    &:hover {
      box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.11);
    }
  }
}

.b_list_section__info_link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.b_list_section__title {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;

  color: var(--c_main);
}
