.b_btn {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 44px;
  padding: 4px 8px;

  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  white-space: normal;
  color: var(--c_text);

  border-radius: 0;
  border: 1px solid var(--c_white);
  background-color: var(--c_white);

  cursor: pointer;
  outline: 0 !important;
  overflow: hidden;
  text-decoration: none !important;
  text-transform: uppercase;

  transition: all 0.2s;

  &.this--small {
    min-height: 36px;
    font-weight: 400;
  }

  &.this--middle {
    min-height: 38px;
  }

  &.this--c_main {
    color: var(--c_white);
    border: 1px solid var(--c_main);
    background-color: var(--c_main);

    &:hover {
      border: 1px solid var(--c_text);
      background-color: var(--c_text);
    }
  }

  &:not(input) {
    display: inline-flex;
  }

  &:hover {
    @media (--tabletMax) {
      color: var(--c_white);

      background-color: var(--c_main);
      border: 1px solid var(--c_main);
    }
  }
}

.b_btn:disabled,
.b_btn:disabled:hover {
  background: #bdc3c6;
  border-color: #808890;
  color: #2f3538;
  cursor: default;
}
