.b_contacts {
  //position: relative;

  &:before {
    //content: "";
    //display: block;
    //padding-top: 40%;
    //width: 100%;
  }

  @media (--tablet) {
    &:before {
      display: none;
    }
  }
}

.b_contacts__content {
  //position: absolute;
  //top: 50%;
  //left: 7%;
  //width: 100%;
  //max-width: 456px;
  //padding: 36px;
  margin-bottom: 24px;

  background-color: var(--c_white);
  //box-shadow: 0 4px 20px rgba(0,0,0,.08);
  //transform: translateY(-50%);
  //z-index: 2;

  @media (--tablet) {
    position: static;
    max-width: none;

    transform: none;
    box-shadow: none;
  }
}

.b_contacts__map {
  //position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  z-index: 1;

  @media (--tablet) {
    position: static;
  }
}

.b_contacts__list {
  margin: 0 0 -8px 0;

  list-style: none;
}

.b_contacts__item {
  display: flex;
  justify-content: start;
  align-items: start;
  min-height: 30px;
  margin-bottom: 8px;
}

.b_contacts__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
}

.b_contacts__text {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2;

  text-align: left;
  color: var(--c_main);

  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 0.2s;

  &:hover {
    text-decoration-color: var(--c_main);
  }
}