.b_header {
  position: fixed;
  top: 0;
  width: 100%;

  background-color: var(--c_white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  z-index: 50;
}

.b_header__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  max-width: var(--max_width);
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 36px;

  @media(--desktopMax) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media(--mobile) {
    height: 90px;
    padding: 6px 8px;
  }
}

.b_header__content_left {
  display: flex;
  align-items: center;

  @media(--mobile) {
    padding: 4px 0;
  }

  @media(--mobile2) {
    flex-direction: column;
  }
}

.b_header__content_right {
  display: flex;
  align-items: center;

  @media(--mobile) {
    flex-direction: column-reverse;
    align-items: end;
  }
}

.b_header__logos {
  display: flex;
  align-items: center;

  @media (--mobile) {
    flex-direction: column;
    align-items: start;
  }
}

.b_header__info {
  display: flex;
  align-items: center;
  margin-right: 36px;

  @media(--mobile) {
    margin-top: 8px;
    margin-right: 0;
  }
}

.b_header__buttons {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
