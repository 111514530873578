.b_about_company {

}

.b_about_company__text {
  margin-top: 0;
  margin-bottom: 24px;

  font-size: 2rem;
  color: var(--c_text);

  &:last-child {
    margin: 0;
  }

  & a {
    color: var(--c_text);
    text-decoration: underline;
    transition: all 0.2s;

    &:hover {
      text-decoration-color: transparent;
    }
  }
}

.b_about_company__logo {
  max-width: 170px;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 24px;
}
