@custom-media --headerMax (width > 1920px);
@custom-media --header (width <= 1920px);

@custom-media --desktopMax (width <= 1200px);
@custom-media --desktopMin (width > 1199px);

@custom-media --desktopSmallMax (width < 1100px);
@custom-media --desktopSmallMin (width >= 1100px);

@custom-media --tablet (width <= 1023px);
@custom-media --tabletMax (width >= 1024px);
@custom-media --tablet2 (width <= 959px);
@custom-media --tablet2Max (width > 959px);
@custom-media --tablet3 (width <= 767px);
@custom-media --tablet3Max (width > 767px);

@custom-media --mobileMax (width >= 640px);
@custom-media --mobile (width <= 639px);
@custom-media --mobileMiddle (width <= 499px);
@custom-media --mobileMiddleMax (width >= 500px);
@custom-media --mobile2 (width <= 424px);
@custom-media --mobile2Max (width > 425px);
@custom-media --mobile3 (width <= 350px);
@custom-media --mobile3Max (width > 349px);


@custom-media --touch (pointer: coarse), (width <= 1100px);

:root {
  --c_main: #e4031f;
  --c_main2: #9b0013;
  --c_bg: #fafafa;
  --c_bg1: rgba(108, 141, 179, 0.8);
  --c_bg2: rgba(179, 108, 108, 0.8);
  --c_text: #1a1a1a;
  --c_gray: #F9F9F9;
  --c_dark_gray: #858585;
  --c_white: #ffffff;

  --font: 'Oswald', 'Roboto', sans-serif;

  --container_padding: 10px;
  --max_width: 1920px;
}
