.b_top_page {
  display: block;
  position: fixed;
  right: 30px;
  bottom: 108px;
  z-index: 101;
  background: rgba(158, 158, 158, .23);
  border-radius: 50%;
  border: 2px solid rgb(200, 26, 7);
  color: var(--c_main);
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 29px;
  transition: .5s all;
  opacity: 0.3;
  cursor: pointer;

  &:not(.b_top_page__show) {
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
  }

  &:after {
    content: "";
    border-left: 4px solid currentColor;
    border-top: 4px solid currentColor;
    width: 14px;
    height: 14px;
    display: block;
    transform: rotate(45deg);
    left: 50%;
    top: 50%;
    position: absolute;
    margin-top: -6px;
    margin-left: -9px;
  }
  
  @media (--mobile) {
    transform: scale(0.66);
  }
}

.b_top_page:hover {
  transition: 0.5s all;
  opacity: 1;
}
