.b_banner_top {

}

.b_banner_top__container {
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 40%;
    width: 100%;
  }

  @media (--desktopMax) {
    &:before {
      padding-top: 56%;
    }
  }
  @media (--mobileMiddle) {
    &:before {
      padding-top: 140%;
    }
  }
}

.b_banner_top__img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.b_banner_top__content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px 32px;

  z-index: 2;

  @media (--mobile) {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.b_banner_top__text_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 900px;
  height: 100%;
  max-height: 400px;
  padding: 48px;

  background-color: var(--c_bg2);

  @media (--tablet) {
    padding: 24px;
  }

  @media (--mobile) {
    padding: 12px 8px;
  }
}

.b_banner_top__slider {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  z-index: 1;
}

.b_banner_top__title {
  margin-bottom: 24px;

  font-size: 4.6rem;
  font-weight: 400;
  line-height: 1.2;

  text-align: center;
  text-transform: uppercase;

  color: var(--c_white);

  @media (--tablet) {
    margin-bottom: 16px;

    font-size: 3.2rem;
    line-height: 1.2;
  }
}

.b_banner_top__text {
  margin-bottom: 24px;

  font-size: 3rem;
  font-weight: 400;
  line-height: 1.4;

  text-align: center;
  color: var(--c_white);

  @media (--tablet) {
    margin-bottom: 16px;

    font-size: 2rem;
    line-height: 1.2;
  }
}

.b_banner_top__button {
  max-width: 220px;
  width: 220px;

  @media (--mobileMiddle) {
    max-width: none;
    width: 100%;
  }
}
