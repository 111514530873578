.b_ask {
  display: flex;
  margin-left: -20px;

  @media (--tablet2) {
    flex-direction: column;
    margin-left: -20px;
  }
}

.b_ask__title {
  margin-bottom: 16px;

  font-size: 3.2rem;
  font-weight: 400;
  line-height: 1.6;

  letter-spacing: 2px;
  text-transform: uppercase;

  text-align: center;
  color: var(--c_main);

  &.this--white {
    color: var(--c_white);
  }
}

.b_ask__left {
  width: calc(50% - 20px);
  margin-left: 20px;

  @media (--tablet2) {
    width: calc(100% - 20px);
    margin-bottom: 24px;
  }
}

.b_ask__right {
  width: calc(50% - 20px);
  margin-left: 20px;

  @media (--tablet2) {
    width: calc(100% - 20px);
  }
}

.b_ask__form_wrapper {

}

.b_ask__disclaimer {
  font-size: 1.2rem;
  text-align: center;
}

.b_ask__text {
  font-size: 4rem;
  line-height: 1.2;
  color: var(--c_main);

  text-align: center;
}