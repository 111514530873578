.b_menu {
  @media (--tablet) {
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin-left: 0;

    z-index: 99999;
    transform: translateX(100%);
    transition: transform 0.2s;

    .b_mob_nav_open & {
      transform: translateX(0);
    }
  }
}

.b_menu___bg_mobile {
  display: none;

  @media (--tablet) {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;

    background: rgb(0 0 0 / 85%);
    z-index: 10;
  }
}

.b_menu__wr {
  @media (--tablet) {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    padding: 24px 80px 32px 32px;

    background-color: var(--c_white);
    overflow: auto;
    z-index: 20;
  }

  @media (--mobile) {
    width: 100%;
    padding: 12px 24px 32px 24px;
  }
}

.b_menu__list {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;

  @media (--tablet) {
    display: block;
    margin: 0 0 -12px 0;

    text-align: left;
  }
}

.b_menu__item {
  flex-shrink: 0;
  margin: 0 4px;

  color: var(--c_main);

  cursor: pointer;

  @media (--tablet) {
    margin: 0 0 12px 0;
  }
}

.b_menu__link {
  padding: 8px;

  font-size: 1.8rem;
  font-weight: 400;
  color: currentColor;

  line-height: 1.2;
  text-transform: uppercase;

  transition: all 0.2s;

  &:hover {
    background-color: var(--c_main);
    color: var(--c_white);
  }

  &.this--active {
    border-bottom: 1px solid var(--c_main);
  }

  @media (--tablet) {
    display: block;
  }
}

.b_nav_ic_mob {
  display: none;
  height: 46px;
  width: 46px;
  line-height: 1;
  padding: 0;
  background: 0 0;
  border: none;

  @media (--tablet) {
    display: block;
  }

  & i {
    background-color: var(--c_main);
    width: 30px;
    height: 2px;
    display: block;
    transition: all 0.2s;
    position: relative;
    margin: 2px auto 0;

    &:after, &:before {
      content: " ";
      position: absolute;
      left: 0;
      background-color: var(--c_main);
      width: 100%;
      height: 2px;
      display: block;
    }

    &:before {
      bottom: 8px;
    }

    &:after {
      top: 8px;
    }
  }
}

.b_mob_nav_open {
  overflow: hidden;

  & .b_nav_ic_mob.this--menu {
    position: fixed;
    top: 12px;
    right: 12px;

    & i {
      background: transparent;

      &:before,
      &:after {
        bottom: auto !important;
        top: 0 !important;
      }

      &:before {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &:after {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}

.b_menu__logos {
  display: none;

  @media (--tablet) {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  @media (--mobile) {
    flex-direction: column;
    align-items: start;
    justify-content: start;

    .b_logo {
      margin-bottom: 8px;
    }
  }
}

.b_menu__bottom_content {
  display: none;

  @media (--tablet) {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: start;
    justify-content: end;

    .b_contacts_info {
      margin-bottom: 17px;
    }
  }
}