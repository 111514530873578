.b_logo_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.b_logo_text__logo {

}

.b_logo_text__text {
  text-transform: uppercase;
  text-align: center;
}