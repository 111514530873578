.b_banner_middle {
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 20%;
    width: 100%;
  }

  @media (--tablet) {
    padding-top: 30%;
  }

  @media (--mobile) {
    padding-top: 60%;
  }
}

.b_banner_middle__container {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 16px;

  z-index: 1;
}

.b_banner_middle__title {
  margin-bottom: 20px;

  font-size: 4rem;
  font-weight: 400;
  line-height: 1;

  text-align: center;
  letter-spacing: 0.464px;

  color: var(--c_white);
  text-shadow: 0 0 5px var(--c_text);

  @media (--mobile) {
    margin-bottom: 10px;
    font-size: 3.2rem;
  }
}

.b_banner_middle__text {
  margin-bottom: 32px;

  font-size: 2.6rem;
  font-weight: 400;
  line-height: 1.4;

  text-align: center;
  letter-spacing: 0.464px;

  color: var(--c_white);
  text-shadow: 0 0 5px var(--c_text);

  @media (--mobile) {
    margin-bottom: 16px;
    font-size: 1.8rem;
  }
}

.b_banner_middle__button {
  max-width: 220px;
  width: 220px;

  @media (--mobileMiddle) {
    max-width: none;
    width: 100%;
  }
}

.b_banner_middle__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
}













