.b_list_information {

}

.b_list_information__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -20px -20px;

  list-style: none;
}

.b_list_information__item {
  display: flex;
  flex-direction: column;
  width: calc(33.3333% - 20px);
  margin-left: 20px;
  margin-bottom: 20px;

  background-color: var(--c_white);

  @media (--tablet) {
    width: calc(50% - 20px);
  }

  @media (--mobile) {
    width: 100%;
  }
}

.b_list_information__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
  padding: 24px 16px;
}

.b_list_information__cover {
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 140%;
    width: 100%;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}

.b_list_information__title {
  font-size: 2.4rem;
  text-align: center;
}

.b_list_information__btn {
  max-width: 220px;
  width: 220px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (--tablet) {
    max-width: none;
    width: 100%;
  }
}












