.b_contacts_info {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.b_contacts_info__item {
  display: flex;
  align-items: center;

  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.2;

  color: var(--c_text);

  &.this--nowrap {
    white-space: nowrap;
  }

  @media (--mobile) {
    font-size: 1.6rem;
  }
}

a.b_contacts_info__item {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.b_contacts_info__icon {
  width: 16px;
  text-align: center;
  margin-right: 8px;
  display: block;
  line-height: 1;
}

