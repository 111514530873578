.b_logo {
  height: 56px;
  margin-right: 24px;

  & img {
    height: 100%;
  }
  
  @media (--mobile2) {
    height: 40px;
  }
}
