h1, .g_title_h1,
h2, .g_title_h2 {
  margin: 0;

  font-weight: 400;
  line-height: 1.1;

  text-align: center;
  color: var(--c_main);

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &.this--mb_0 {
    margin-bottom: 0;
  }
}

.g_subtitle_h1,
.g_subtitle_h2 {
  font-weight: 400;
  line-height: 1.2;

  text-align: center;
  color: var(--c_main);
}

h1, .g_title_h1 {
  margin-bottom: 14px;
  font-size: 56px;

  @media (--tablet2) {
    margin-bottom: 16px;
    font-size: 48px;
  }

  @media (--mobile) {
    margin-bottom: 12px;
    font-size: 40px;
  }
}

h2, .g_title_h2 {
  margin-bottom: 12px;
  font-size: 48px;

  @media (--tablet2) {
    margin-bottom: 14px;
    font-size: 40px;
  }

  @media (--mobile) {
    margin-bottom: 12px;
    font-size: 32px;
  }
}

.g_subtitle_h1 {
  margin-top: 14px;
  font-size: 32px;

  @media (--tablet2) {
    margin-bottom: 16px;
    font-size: 24px;
  }
}

.g_subtitle_h2 {
  margin-bottom: 12px;
  font-size: 24px;

  @media (--tablet2) {
    margin-bottom: 14px;
    font-size: 18px;
  }
}

.g_title_section {
  padding: 10px 10px 14px 10px;

  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1;

  text-align: center;
  color: var(--c_white);

  background-color: var(--c_main);
}