.b_about_card {
  & table {
    border-collapse: collapse;
  }
}

.b_about_card__items {
  @media (--mobile2) {
    border-left: 1px solid var(--c_text);
    border-right: 1px solid var(--c_text);
  }
}

.b_about_card__item {
   @media (--mobile2) {
     display: flex;
     flex-direction: column;
   }
}

.b_about_card__title,
.b_about_card__value {
  padding: 4px 8px;

  font-size: 1.8rem;
  vertical-align: top;

  border: 1px solid var(--c_text);

  @media (--mobile2) {
    border-top: 1px solid var(--c_text);
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
}

.b_about_card__title {
  width: 40%;

  @media (--mobile2) {
    width: 100%;

    font-size: 1.6rem;
    text-align: center;
  }
}

.b_about_card__value {
  width: 60%;

  @media (--mobile2) {
    position: relative;
    width: 100%;

    .b_about_card__item:last-child & {
      border-bottom: 1px solid var(--c_text);
    }
  }
}
