.b_list_brand {

}

.b_list_brand__list {
  display: flex;
  justify-content: left;

  margin: 0;

  list-style: none;

  @media (--tablet) {
    flex-wrap: wrap;
  }

  @media (--mobile) {
    margin-right: 0;
  }
}

.b_list_brand__item {
  display: flex;
  flex-direction: column;
  width: 25%;

  background-color: var(--c_white);
  border: 1px solid #F7F7F7;

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.11);
    z-index: 1;
  }

  @media (--tablet) {
    width: 50%;
  }

  @media (--mobile) {
    width: 100%;
    margin-right: 0;
  }
}

.b_list_brand__cover {
  position: relative;
  background-color: var(--c_white);

  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 64%;

    @media (--mobile) {
      padding-top: 48%;
    }
  }

  & .b_list_brand__img_wr {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 16px;

    & img {
      width: auto;
      height: auto;
    }
  }
}

.b_list_brand__info {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 144px;
  margin-left: 28px;
  margin-right: 28px;
  margin-bottom: 18px;
  padding: 24px 36px;

  background-color: var(--c_white);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.17);

  z-index: 2;
}

.b_list_brand__title {
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;

  color: var(--c_main);
}
